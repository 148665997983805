<template>
  <div class="pptContainer">
    <div id="pptx"></div>
    <div id="firstSlide" class="slide">
      <div>
        <div style="margin-bottom: 10px; text-align: center; font-size: 30px">
          {{ sessionName }}
        </div>
        <vue-qr :text="watchUrl" :size="200"></vue-qr>
      </div>
    </div>
    <div class="cover" v-loading="loading">
      <div>
        <canvas id="myCanvas" style="width: 1280px; height: 720px"></canvas>
        <div style="margin-top: 10px; text-align: center">
          <el-button type="primary" @click="last">上一页</el-button>
          <el-button type="primary" @click="next">下一页</el-button>
          <el-button type="primary" @click="toScreen">全屏</el-button>
          <el-button type="primary" @click="codeVisible = true"
            >演示者二维码</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog
      title="演示者二维码"
      :visible.sync="codeVisible"
      :append-to-body="true"
    >
      <div style="text-align: center">
        <vue-qr :text="presentUrl" :size="260"></vue-qr>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSessionPPT, getPPTNotes } from "../../api/api";
import html2canvas from "html2canvas";
import VueQr from "vue-qr";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      currentIndex: 0,
      id: this.$route.query.id,
      session_id: this.$route.query.session_id,
      sessionName: "",
      color: "#000000",
      url: "",
      client: null,
      watchUrl: "",
      loading: false,
      presentUrl: "",
      codeVisible: false,
    };
  },
  mounted() {
    this.presentUrl = `http://canhaohui-fwh.reachable-edu.com/control?id=${this.id}&session_id=${this.session_id}`;
    this.fetchData();
    var canvas = document.getElementById("myCanvas");

    // 监听全屏事件
    canvas.addEventListener("fullscreenchange", () => {
      if (document.fullscreenElement) {
        console.log("进入全屏");
        canvas.style.width = window.screen.width + "px";
        canvas.style.height = window.screen.height + "px";
        this.selectItem();
      } else {
        console.log("退出全屏");
        canvas.style.width = "1280px";
        canvas.style.height = "720px";
        this.selectItem();
      }
    });
    // this.getPPTNotes();
  },
  beforeDestroy() {
    if (this.client) {
      this.client.close();
    }
  },
  methods: {
    getPPTNotes() {
      getPPTNotes({ ppt_url: this.url }).then((res) => {
        console.log(res);
      });
    },
    initSocket() {
      this.client = new WebSocket("wss://team-chat.learningfirst.cn:9009");
      this.client.onopen = () => {
        console.log("WebSocket连接成功");
        this.client.send(
          JSON.stringify({
            type: "login",
            pptId: this.id,
            userId: "666",
          })
        );
      };
      this.client.onmessage = (e) => {
        console.log(e.data);
        let obj = JSON.parse(e.data);
        if(obj.type === '登录成功' || obj.msg === '切换页面') {
          this.currentIndex = obj.data;
          this.selectItem();
        }
      };
    },
    fetchData() {
      this.loading = true;
      getSessionPPT({
        id: this.id,
      }).then((res) => {
        if (res.data.code === 0) {
          this.url = res.data.data.ppt_url;
          this.sessionName = res.data.data.session.name;
          this.watchUrl = `http://canhaohui-fwh.reachable-edu.com/watch?id=${this.id}&session_id=${this.session_id}`;
          $("#pptx").pptxToHtml({
            pptxFileUrl: this.url,
            slideScale: "100%",
            slideMode: false,
            keyBoardShortCut: false,
          });
          setTimeout(() => {
            //添加第一页
            let firstSlide = document.getElementById("firstSlide");
            let area = document.getElementById("all_slides_warpper");
            let first = area.childNodes[0];
            firstSlide.style.width = first.style.width;
            firstSlide.style.height = first.style.height;
            area.insertBefore(firstSlide, first);
            this.initSocket();
            this.loading = false;
          }, 2000);
        }
      });
    },
    selectItem() {
      let dom = document.getElementById("all_slides_warpper");
      let currentItem = dom.childNodes[this.currentIndex];
      html2canvas(currentItem).then((canvas) => {
        let myCanvas = document.getElementById("myCanvas");
        myCanvas.width = canvas.width;
        myCanvas.height = canvas.height;
        let ctx = myCanvas.getContext("2d");
        ctx.drawImage(canvas, 0, 0);
      });
    },
    next() {
      let dom = document.getElementById("all_slides_warpper");
      if (this.currentIndex < dom.childNodes.length - 1) {
        this.client.send(
          JSON.stringify({
            type: "changeIndex",
            pptId: this.id,
            index: this.currentIndex + 1,
          })
        );
      }
    },
    last() {
      if (this.currentIndex > 0) {
        this.client.send(
          JSON.stringify({
            type: "changeIndex",
            pptId: this.id,
            index: this.currentIndex - 1,
          })
        );
      }
    },
    toScreen() {
      let myCanvas = document.getElementById("myCanvas");
      myCanvas.requestFullscreen();
    },
  },
};
</script>

<style lang="scss">
.slide {
  border-radius: 10px !important;
  border: 1px solid #666 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
#pptx {
  width: 100%;
  height: 600px;
  overflow-y: scroll;
  #all_slides_warpper {
    height: 100% !important;
    .slide {
      margin-bottom: 0;
    }
  }
}
.pptContainer {
  position: relative;
  .cover {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1999;
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  #myCanvas {
    margin: 0 auto;
  }
}
</style>